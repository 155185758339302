#root {
    width:100%;
  }

body,
html {
  height: 100%;
  background: #222222;
  font-display: swap;
  font-family: "Lato", sans-serif;
  color:#aaa;
  width:100%;
  display:inline-flex;
}